<template>
  <section id="CodigoQR">
    <v-img
      :src="require('@/assets/Home/qr.jpg')"
      class="white--text"
      height="100%"
      max-height="773px"
    >
      <v-container class="fill-height px-4 py-md-12 py-lg-12 my-12">
        <v-responsive
          height="100%"
          width="100%"
        >
          <v-row class="mx-0 py-md-12 py-lg-12" align="center">
            <v-col cols="12" sm="4" md="4" lg="4" class="pr-0">
              <v-img
                :src="require('@/assets/Home/code2-qr-phone.png')"
                class="white--text"
                :class="{'mx-auto': $vuetify.breakpoint.smAndDown }"
              ></v-img>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" class="pr-0">
              <v-row class="mx-0">
                <v-col cols="12" class="pb-0 d-flex justify-end">
                  <span class="security-landing text-right d-flex white--text" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly, 'px-5': $vuetify.breakpoint.smAndDown}">Código QR</span>
                </v-col>
              </v-row>
              <v-row class="mx-0">
                <v-col cols="12" class="px-1">
                  <p class="p-landing text-right white--text" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly, 'px-5': $vuetify.breakpoint.smAndDown}"> <strong>Lugar:</strong> Lo puedes tener en tu página web, en la puerta de tu restaurante, en cada mesa...</p>
                  <p class="p-landing text-right white--text" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly, 'px-5': $vuetify.breakpoint.smAndDown}"> <strong>Información:</strong> Puedes poner imágenes, descripción de los platos, vinos recomendados, varias monedas y productos con variaciones, así como la información de los alérgenos.</p>
                  <p class="p-landing text-right white--text" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly, 'px-5': $vuetify.breakpoint.smAndDown}"> <strong>Clientes:</strong> Los clientes desde antes de ir a tu restaurante ya pueden saber que platos tienes y que les apetece comer. Fácil de leer para tus clientes</p>
                  <p class="p-landing text-right white--text" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly, 'px-5': $vuetify.breakpoint.smAndDown}"> 
                    <strong>Rapidez:</strong> Nuestra App es más rápida que descargarse un PDF,<br>no hagas esperar a tus clientes.
                  </p>
                  </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'CodigoQR',

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '50vh' : '40vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
      logoSize () {
        const size = this.$vuetify.breakpoint.mdAndUp ? '10' : '5'

        return `${size}rem`
      },
      styleLogo(){
        return {
           'margin-top': this.$vuetify.breakpoint.mdAndUp ? '-30px !important' : '-15px !important'
        }
      }
    },
  }
</script>
